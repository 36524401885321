import {
  type Signal,
  isDevMode,
  assertInInjectionContext,
  inject,
  DestroyRef,
  ElementRef,
} from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { type Observable, pipe, tap } from 'rxjs';
import { PageTitleService } from './page-title.service';
import { type HeaderData } from './page-title-data';

/**
 * Helper function to guard against multiple usages of pageTitle within the same component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const referenceSet = new WeakSet<any>();
function assertSinglePageTitlePerInjector(ref: ElementRef) {
  if (referenceSet.has(ref.nativeElement)) {
    throw new Error(
      `Every component should only contain a single PageTitle(), to fix this issue please rewrite your code to only contain a single pageTitle \n` +
        `pageTitle allows you to pass in a \`signal<HeaderData>/observable<HeaderData>\` if you need to change the title based on state changes from the component`,
    );
  }

  referenceSet.add(ref.nativeElement);
}

export function pageTitle(
  input: HeaderData | Signal<HeaderData> | Observable<HeaderData>,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isDevMode() && assertInInjectionContext(pageTitle);

  const destroyRef = inject(DestroyRef);
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isDevMode() && assertSinglePageTitlePerInjector(inject(ElementRef));

  const service = inject(PageTitleService);

  // setup updater
  let index: number | null = null;
  const updater = rxMethod<HeaderData>(
    pipe(
      tap((data) => {
        if (typeof index === 'number') {
          service.replace(index, data);
        } else {
          index = service.push(data);
        }
      }),
    ),
  );

  // when pageTitleChanges
  let sub: ReturnType<typeof updater> | null = updater(input);

  // when destroyed
  destroyRef.onDestroy(() => {
    sub?.destroy();
    sub = null;
    service.pop();
  });
}
